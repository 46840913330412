<template>
  <section id="dashboard-analytics">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Companies -->
          <b-col
            v-show="userData.userRoleId===userRole.ADMIN"
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="id-company"
                v-model="company"
                :placeholder="$t('SELECT_COMPANY')"
                :clearable="false"
                label="name"
                class="flex-grow-1 ml-50 mr-1"
                :options="allCompanies"
                @input="handleCompanySelectChange()"
              />
            </div>
          </b-col>
          <!-- Search Content-->
          <b-col
            cols="12"
            :md="userData.userRoleId===userRole.ADMIN ? 4 : 6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="id-content"
                v-model="content"
                :placeholder="$t('SELECT_CONTENT')"
                :options="userData.userRoleId === userRole.ADMIN ? contents : allContents"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                @input="handleContentSelectChange()"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$router.push({ name: 'content-view', params: { id: content.id }})"
            >
              <span>{{ $t('VIEW_CONTENT') }} </span>
              <feather-icon icon="AwardIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row class="match-height">

      <b-col
        lg="12"
        md="12"
      >
        <analytics-game-view
          v-if="content.id"
          :content-data="content"
        />
      </b-col>
    </b-row>
    <b-row v-show="content.recordStatus===0">
      <b-col
        cols="12"
        md="4"
      >
        <content-time-line
          v-if="content.id"
          class="p-2"
          :content-id="content.id"
          :can-edit-content="true"
          :settimes="allContentTasks"
        />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <content-task-list
          v-if="content.id"
          class="p-2"
          :content-id="content.id"
          :can-edit-content="true"
          :all-content-tasks="allContentTasks"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import AnalyticsGameView from './AnalyticsGameView.vue'
import ContentTaskList from '../../content/content-view/ContentTaskList.vue'
import ContentTimeLine from '../../content/content-view/ContentTimeLine.vue'
import { countingObject } from '@/constants/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    AnalyticsGameView,
    ContentTaskList,
    ContentTimeLine,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinDate],
  data() {
    return {
      contents: [],
      content: useJwt.getContent() || {},
      tasks: [],
      setResults: [],
      performedTasks: [],
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      currentDate: new Date(),
      company: '',
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  computed: {
    ...mapState('company', ['allCompanies']),
    ...mapState('task', ['allContentTasks']),
    ...mapState('settime', ['allSettimes']),
    ...mapState('content', ['allContents']),
    ...mapState('team', ['allTeams']),
  },
  async created() {
    await this.fetchAllCompanies()
    await this.fetchAllContents()
    await this.fetchContentTeams()
  },
  methods: {
    ...mapActions('content', ['fetchAllContents']),
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('team', ['fetchAllTeams']),
    ...mapActions('task', ['fetchContentTaskByID']),
    ...mapActions('settime', ['fetchAllSettimes']),
    handleCompanySelectChange() {
      this.contents = []
      this.contents = this.allContents.filter(i => i.companyId === this.company.id)
      if (this.contents.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.content = this.contents[0]
        this.handleContentSelectChange()
      } else {
        this.content = constants.EMPTY_CONTENT
      }
    },
    async fetchContentTeams() {
      try {
        if (!this.content.id) {
          return
        }
        this.content = {
          ...this.content,
          usersCount: 0,
          groupCount: 0,
          users: [],
        }
        await this.fetchAllTeams(this.content.id)
        const results = countingObject(this.allTeams)
        this.content = {
          ...this.content,
          usersCount: results.usersCount,
          groupCount: results.groupCount,
          users: results.users,
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
    handleContentSelectChange() {
      this.fetchContentTeams()
      useJwt.setContent(this.content)
    },
  },
}
</script>

<template>
  <b-card
    v-if="allContentTasks"
    no-body
  >
    <!-- Header: Habit & Behaviour -->
    <div class="d-flex">

      <h4 class="mb-2 mr-1">
        {{ $t('TASK_SUBTASK') }}
      </h4>
      <feather-icon
        v-if="canEditContent"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'task-list-id', params: { id: contentId }})"
      />
    </div>
    <app-timeline
      v-for="(contentTask,index) in allContentTasks"
      :id="contentTask.id.toString()"
      :key="contentTask.id"
    >
      <!-- Sub task-->
      <app-timeline-item
        :id="contentTask.id.toString()"
        :key="index"
        variant="primary"
        icon="FileTextIcon"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ contentTask.name }} {{ contentTask.points !== null ? '(' + contentTask.points + ')' : '' }}</h6>
        </div>
        <p v-html="contentTask.description" />
        <b-collapse
          :id="contentTask.id.toString()"
          visible
        >
          <b-list-group
            flush
            class="mt-1"
          >
            <b-list-group-item
              v-for="(subtask,i) in contentTask.subtasks"
              :id="subtask.id.toString()"
              :key="i"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span> <b-avatar
                size="30"
                :text="subtask.sequence.toString()"
                variant="light-warning"
              />{{ subtask.name }}  {{ subtask.points !== null ? `(${subtask.points})` : '' }}  </span>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BCard,
  BCollapse,
  BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinList],
  props: {
    allContentTasks: {
      type: Array,
      default: () => [],
      required: true,
    },
    canEditContent: {
      type: Boolean,
      default: () => true,
    },
    contentId: {
      type: Number,
      default: 0,
      required: true,
    },

  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;
    .todo-task-list .todo-item:hover & {
    visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            :icon="mapToContentTypeIcon(content.contentTypeId).icon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ content.name }}
            <b-badge
              variant="light-primary"
            >
              {{ content.contentType.name }}
            </b-badge>
            <b-badge
              class="ml-1"
              variant="light-warning"
            >
              {{ content.contentFrequency.name }}
            </b-badge>
            <b-badge
              class="ml-1"
              variant="light-info"
            >
              {{ content.contentStatus.name }}
            </b-badge>
            <b-button
              v-if="content.recordStatus===0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="shadow ml-1"
              @click="confirmAndPublishContent(content)"
            >
              <span>{{ $t('PUBLISH_GAME') }} </span>
            </b-button>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="content.description" />
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->
      <!-- start date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.start) }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- end date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.end) }}
          </h6>
        </b-media-body>
      </b-media>

      <!--Landing url: -->
      <b-media
        v-if="content.recordStatus!==0"
        no-body
      >

        <b-media-aside

          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(landingLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="landingLink"
          > {{ $t('LANDING_LINK') }} </b-link>
        </b-media-body>
      </b-media>

      <!--Players invitation link: -->
      <b-media
        v-if="content.recordStatus!==0"
        no-body
      >
        <b-media-aside
          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(invitationLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="invitationLink"
          > {{ $t('PLAYERS_INVITATION_LINK') }} </b-link>
        </b-media-body>
      </b-media>
      <!--Leaderboard link -->
      <b-media
        v-if="content.recordStatus!==0"
        no-body
      >
        <b-media-aside
          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(leaderboardLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="leaderboardLink"
          > {{ $t('LEADER_BOARD') }}</b-link>
        </b-media-body>
      </b-media>
      <!-- avatar group -->
      <b-avatar-group
        v-if="content.users"
        class="mt-2 pt-50"
        size="33"
      >
        <b-avatar
          v-for="(user,index) in content.users.slice(0, 12)"
          :key="index"
          v-b-tooltip.hover.bottom="user.firstName + ' ' + user.lastName"
          :variant="changeVariant(index)"
          :text="avatarText(user.firstName.substring(0,1))"
          class="pull-up"
          :to="{ name: 'user-profile-id', params: { id: user.id } }"
        />
        <span
          v-if="content.users.length > 12"
          class="ml-1"
        > ...</span>
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          {{ content.usersCount }} Players  -  {{ content.groupCount }} Teams
        </h6>
      </b-avatar-group>

    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BBadge,
  BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton, BLink,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/named
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'
import { mapToContentTypeIcon } from '@/constants/utils'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
    BLink,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinDate, mixinList],
  props: {
    contentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: {
        id: 0,
        name: '',
        description: '',
        start: '',
        end: '',
        usersCount: 0,
        groupCount: 0,
        users: [],
      },
      invitationLink: '',
      leaderboardLink: '',
      landingLink: '',
    }
  },
  watch: {
    contentData: {
      handler(newVal) {
        if (newVal) {
          this.content = { ...newVal }
          this.setLink(this.content)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('content', ['updateContent']),
    setLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}onboarding-user/${data.uuid}`
      this.leaderboardLink = `${process.env.VUE_APP_HOST}leaderboard/${data.uuid}`
      this.landingLink = `${process.env.VUE_APP_HOST}${data.uuid}`
    },
    confirmAndPublishContent(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.PUBLISH_CONFIRMATION')} ${data.name}  content ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.baseContent.recordStatus = 1
            // eslint-disable-next-line no-param-reassign
            data.recordStatus = 1
            this.updateContent(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_PUBLISHED'))
              }
            })
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()
    const copyLink = link => {
      copy(link)
      toast({
        component: ToastificationContent,
        props: {
          title: 'The link is copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }
    const {
      errorMessage,
      successMessage,
    } = mixinAlert()
    return {
      copyLink,
      successMessage,
      errorMessage,
      avatarText,
      mapToContentTypeIcon,
    }
  },
}
</script>
